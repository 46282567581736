import React from 'react'
import { motion } from 'framer-motion'
import tw from 'twin.macro'

const Container = tw(motion.div)`inset-0 fixed h-screen w-full items-center flex justify-center z-[9999] sm:z-50`
const BackDrop = tw(motion.div)`absolute inset-0 h-full w-full bg-black/50 z-10`
const ModalContainer = tw(motion.div)`absolute w-full h-full sm:h-auto sm:w-[66%] 2xl:w-[70%] sm:rounded-emf bg-white z-20 max-h-screen`
const ModalClose = tw.div`cursor-pointer absolute top-4 right-4`
const ModalBody = tw.div`h-full py-spacingM-xs px-offset-xs md:px-offset-lg flex items-center justify-center`

const Modal = ({ children, className, state, onClick, modalContainerClassName }) => {
	const modalVariants = {
		initial: {
			y: 50,
			opacity: 0,
			visibility: 'hidden',
		},
		active: {
			y: 0,
			opacity: 1,
			visibility: 'visible',
		},
	}
	const backdropVariants = {
		initial: {
			opacity: 0,
			visibility: 'hidden',
		},
		active: {
			opacity: 1,
			visibility: 'visible',
		},
	}

	return (
		<Container variants={backdropVariants} initial='initial' animate={state ? 'active' : 'initial'} className={className}>
			<BackDrop onClick={onClick} />
			<ModalContainer className={modalContainerClassName} variants={modalVariants} initial='initial' animate={state ? 'active' : 'initial'}>
				<ModalClose onClick={onClick}>
					<svg width='30' height='30' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M1 1L21.5 21.5' stroke='#666666' strokeWidth='2.5' />
						<path d='M22 1L1.5 21.5' stroke='#666666' strokeWidth='2.5' />
					</svg>
				</ModalClose>
				<ModalBody>{children}</ModalBody>
			</ModalContainer>
		</Container>
	)
}

export default Modal
